import {getCSRFToken} from "../../utils";
import axios from "axios";

const AuthApi = {
    check: async () => {
        const {data} = await axios.get("/session/new");
        return data;
    },
    login: async (obj) => {
        const {data} = await axios.post("/en/session", {
            authenticity_token: getCSRFToken(),
            session: {...obj},
        });

        return data;
    },
    logout: async () => {
        const {data} = await axios.delete("/session", {
            headers: {
                "X-CSRF-Token": getCSRFToken(),
            },
        });
        console.log(data);
    },
    setActive: async (project_hashid) => {
        const {data} = await axios.post("session/set_active", {
            authenticity_token: getCSRFToken(),
            session: {
                project_hashid: project_hashid,
            },
        });

        return data;
    },
    forgotPassword: async (obj) => {
        const {data} = await axios.post("session/forgot_password", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },
    setupAccount: async (obj) => {
        const {data} = await axios.post("session/setup_account", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },
    signUp: async (obj) => {
        const {data} = await axios.post("session/signup", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },
    productSignUp: async (obj) => {
        try {
            const {data} = await axios.post("session/signup_product", {
                authenticity_token: getCSRFToken(),
                session: obj,
            });

            return data;
        } catch (error) {
            return {
                code: error.response.status,
                message: error.response.data.message,
            };
        }
    },
    masqueradeUser: async (obj) => {
        const {data} = await axios.post("session/masquerade", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },
    getWorkspacesUsagesByAccount: async (obj) => {
        const {data} = await axios.post("projects/workspaces", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },
    checkEmailValidity: async (obj) => {
        const {data} = await axios.post("session/check_email_validity", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },
    confirmEmail: async (obj) => {
        const {data} = await axios.post("session/confirm_email", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },
    googleSignIn: async (obj) => {
        const {data} = await axios.post("session/google_signin", {
                authenticity_token: getCSRFToken(),
                session: obj,
            },
            {
                credentials: 'include',
            });

        return data;
    },

    getEnv: async (obj) => {
        const {data} = await axios.post("session/get_env", {
            authenticity_token: getCSRFToken(),
            session: obj,
        });

        return data;
    },


};

export default AuthApi;
