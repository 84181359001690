import { mapActions, mapGetters, mapMutations } from "vuex";
import { secondsToTimecode, timecodeToSeconds } from "../../../utils";
import { ASCENDING, DESCENDING, LATEST, OLDEST } from "../../sorting_algos";

export const MEDIAME_MODULE_NAME = "mediame";

export const MEDIAME_MODAL_ACTIONS = {
  UPLOAD: "UPLOAD",
  ADD_TAG: "ADD_TAG",
  EDIT_TAG: "EDIT_SMART_TAG",
  EMAIL_MEDIA: "EMAIL_MEDIA",
  UPDATE_USER_NAME: "UPDATE_USER_NAME",
  MEDIA_INFO: "MEDIA_INFO",
  GET_STARTED: "GET_STARTED",
  ADD_PROJECT: "ADD_PROJECT",
  STUDIO_SESSION: "STUDIO_SESSION",
  MEDIAME_AI: "MEDIAME_AI",
};

const SOURCE = {
  DeliverME: "deliver_me",
  DirectME: "direct_me",
  MediaME: "media_me",
};

export const MEDIAME_SORT_OPTIONS = {
  ascending: { title: "Ascending [A->Z]", algo: ASCENDING },
  descending: { title: "Descending [Z->A]", algo: DESCENDING },
  oldest: { title: "Oldest", algo: OLDEST },
  latest: { title: "Most Recent", algo: LATEST },
};

export const MAX_GET_STARTED_ACTIONS = 2;

const DEFAULT_ITEMS_PER_PAGE = 16;

let __takePlayer = null;

const initState = {
  is_connected: false,
  is_new_user: false,

  media_list: [],
  media: null,
  favorites: [],

  tags_list: [],
  tags_list_with_id: [],

  modal: null,

  uploadedFiles: [],
  visibility: "Everyone",

  selectedMedia: [],

  isLoading: { index: false, load_media: false },

  query: "",
  appliedFilter: "All",
  sort: "ascending",
  type: "all",

  activeTab: "media",
  localStorageFavKey: null,

  startIndex: 0,
  perPage: DEFAULT_ITEMS_PER_PAGE,
  endIndex: DEFAULT_ITEMS_PER_PAGE - 1,
  currentPage: 1,
  //favorites: [],

  get_started_actions_performed: [],
  get_started_metadata: { transcribe_id: null, upload_id: null },
};

const add_reply = (logs, log) => {
  logs.forEach((l) => {
    if (l.id === log.parent_log_id) {
      l.replies.push(log);
      l.replies.sort((a, b) => a.timecode_in - b.timecode_in);
    } else {
      add_reply(l.replies, log);
    }
  });
};

const update_reply = (replies, log) => {
  replies.forEach((reply, index) => {
    if (reply.id === log.id) {
      replies.splice(index, 1, log);
    } else {
      update_reply(reply.replies, log);
    }
  });
};

const delete_reply = (replies, params) => {
  replies.forEach((log) => {
    if (log.id === params.parent_log_id && log.replies) {
      const index = log.replies.findIndex((reply) => reply.id === params.id);
      if (index > -1) {
        log.replies.splice(index, 1);
      } else {
        delete_reply(log.replies, params);
      }
    } else {
      delete_reply(log.replies, params);
    }
  });
};

/**
 * Assigns timecode to the given log and its replies
 *
 * @param {Object} log - The log object to assign timecode to
 * @param {number} offset - The offset in seconds to apply to the timecode
 * @returns {Object} - The assigned log object
 */
const assign_tc = (log, offset, frame_rate) => {
  log.start_timecode = secondsToTimecode(offset, log.timecode_in, frame_rate);
  log.replies.sort((a, b) => a.timecode_in - b.timecode_in).map((l) => assign_tc(l, offset, frame_rate));
  return log;
};

const MediaMEModule = {
  namespaced: true,
  state: () => initState,
  mutations: {
    // COMMON
    RESET(state) {
      state.is_connected = false;
      state.media = null;
      state.modal = null;
      state.media_list = [];
    },
    connected(state, flag) {
      state.is_connected = flag;
    },

    //LIST Media
    index(state, { body: data, params }) {
      let items = [];
      items = [...(data.videos || []), ...(data.clips || []), ...(data.broadcast_recordings || [])];
      items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      // figure out all unique tags from list of medias
      let tags = {};
      let tags_with_id = [];
      const unique_key = "id";

      items.forEach((item) => {
        //Change type to audio if ends in .mp3 or .wav
        if (item?.name?.endsWith(".mp3") || item?.name?.endsWith(".wav")) item.type = "audio";

        item.tag_list.forEach((tag) => {
          tags[tag] = 0;
        });
        tags_with_id = [...tags_with_id, ...item.tag_obj];
      });
      state.tags_list = Object.keys(tags);
      state.tags_list_with_id = [...new Map(tags_with_id.map((e) => [e[unique_key], e])).values()];

      state.media_list = items;

      state.isLoading.index = false;
      //Add Tag List with id as well
    },

    load_media(state, { body: items, params }) {
      items.forEach((item) => {
        const index = state.media_list.findIndex(
          (media) => media.hashid === item.hashid && media.belongs_to === item.belongs_to
        );
        if (index > -1) {
          state.media_list.splice(index, 1, { ...state.media_list[index], ...item, url_checked: true });
        }
      });

      state.isLoading.load_media = false;
    },
    destroy(state, { params }) {
      state.media_list = state.media_list.filter((media) => media.hashid !== params.hashid);
    },
    create_log(state, { body: log, params }) {
      if (state.media) {
        if (!(state.media.hashid === params.hashid && state.media.belongs_to === params.belongs_to)) {
          return;
        }

        log = assign_tc(log, state.media.offset, state.media.frame_rate);
        if (params.parent_log_id) {
          add_reply(state.media.logs, log);
        } else {
          state.media.logs.push(log);
          state.media.logs.sort((a, b) => a.timecode_in - b.timecode_in);
        }
      }
    },
    update_log(state, { body: log, params }) {
      if (state.media) {
        if (!(state.media.hashid === params.hashid && state.media.belongs_to === params.belongs_to)) {
          return;
        }

        log = assign_tc(log, state.media.offset, state.media.frame_rate);
        if (params.parent_log_id) {
          update_reply(state.media.logs, log);
        } else {
          const index = state.media.logs.findIndex((l) => l.id === params.id);
          if (index !== -1) {
            state.media.logs.splice(index, 1, log);
          }
        }
      }
    },

    destroy_log(state, { params }) {
      if (state.media) {
        if (!(state.media.hashid === params.hashid && state.media.belongs_to === params.belongs_to)) {
          return;
        }

        if (params.parent_log_id) {
          delete_reply(state.media.logs, params);
        } else {
          const index = state.media.logs.findIndex((l) => l.id === params.id);
          if (index > -1) {
            state.media.logs.splice(index, 1);
          }
        }
      }
    },

    // create media upload
    create(state, { body: payload, params }) {
      if (payload) {
        payload.forEach((p) => state.media_list.unshift(p));
      }
      const uploaded_file_ids = params.files.map((p, index) => {
        return { hashid: payload[index].hashid, belongs_to: payload[index].belongs_to };
      });
      const transcribed_file_ids = params.files
        .filter((p, index) => p.transcribe)
        .map((p, index) => {
          return { hashid: payload[index].hashid, belongs_to: payload[index].belongs_to };
        });

      if (transcribed_file_ids.length > 0) {
        state.get_started_metadata.transcribe_id = transcribed_file_ids;
        // state.get_started_actions_performed.push("transcribe");
      } else if (uploaded_file_ids.length > 0) {
        state.get_started_metadata.upload_id = uploaded_file_ids;
        // state.get_started_actions_performed.push("upload");
      }
    },

    show(state, { body }) {
      if (state.media && body) {
        if (!(state.media.hashid === body.hashid && state.media.belongs_to === body.belongs_to)) {
          return;
        }
      }
      if (body) {
        if (body.timecode_in && body.frame_rate) {
          body.offset = timecodeToSeconds(body.timecode_in, body.frame_rate);
        }
        body.logs = body.logs
          .sort((a, b) => a.timecode_in - b.timecode_in)
          .map((l) => assign_tc(l, body.offset, body.frame_rate));

        body.transcriptions = body.transcriptions.map((t) => {
          t.start_seconds = timecodeToSeconds(t.start_time, body.frame_rate) - body.offset;
          return t;
        });
        body.transcriptions.sort((a, b) => a.start_seconds - b.start_seconds);
        // if media is present then ignore below from body
        if (state.media) {
          body.locations_list = state.media.locations_list;
          body.casts_list = state.media.casts_list;
          body.transcriptions = state.media.transcriptions;
          body.logs = state.media.logs;
          body.casts = state.media.casts;
        }

        if (body?.name?.endsWith(".mp3") || body?.name?.endsWith(".wav")) body.type = "audio";
      }
      state.media = body;
    },

    update(state, { body, params }) {
      const index = state.media_list.findIndex((m) => m.hashid === params.hashid && m.belongs_to === params.belongs_to);
      if (index > -1) {
        state.media_list.splice(index, 1, { ...state.media_list[index], ...body });
      }
    },

    create_tag(state, { body: new_tag, params }) {
      params.items.forEach((item) => {
        const index = state.media_list.findIndex(
          (media) => media.hashid === item.hashid && media.belongs_to === item.belongs_to
        );
        if (index > -1) {
          state.media_list[index].tag_list.unshift(params.tag_content);
          //add tag to tag_obj if not already exist
          const is_tag_in_tag_obj = state.media_list[index].tag_obj.find(
            (each_tag_obj) => each_tag_obj.id === new_tag.id
          );
          if (!is_tag_in_tag_obj) state.media_list[index].tag_obj.unshift(new_tag);
        }
      });

      //Add tag to tag_list_with_id if not already present
      const tag_index = state.tags_list_with_id.findIndex((each_tag) => each_tag.id === new_tag.id);
      if (tag_index === -1) state.tags_list_with_id = [...state.tags_list_with_id, new_tag];
    },
    destroy_tag(state, { params }) {
      params.items.forEach((item) => {
        const index = state.media_list.findIndex(
          (media) => media.hashid === item.hashid && media.belongs_to === item.belongs_to
        );
        if (index > -1) {
          const tag_index = state.media_list[index].tag_list.findIndex((tag) => tag === params.tag_content);
          if (tag_index > -1) {
            state.media_list[index].tag_list.splice(tag_index, 1);
          }
        }
      });

      const isTagUsed = state.media_list.some((media) => media.tag_list.includes(params.tag_content));
      if (!isTagUsed) {
        const tagIndex = state.tags_list.findIndex((tag) => tag === params.tag_content);
        if (tagIndex > -1) {
          state.tags_list.splice(tagIndex, 1);
        }
      }
    },
    updateSpeakerLabel(state, payload) {
      if (state.media) {
        if (!(state.media.hashid === payload.hashid && state.media.belongs_to === payload.belongs_to)) {
          return;
        }

        const transcriptIndex = state.media.transcriptions.findIndex((transcript) => transcript.id === payload.id);
        if (transcriptIndex !== -1) {
          const oldLabel = state.media.transcriptions[transcriptIndex].speaker_label;

          // Update the speaker_label in all objects where it matches the old label
          state.media.transcriptions.forEach((transcript) => {
            if (transcript.speaker_label === oldLabel) {
              transcript.speaker_label = payload.speaker_label;
            }
          });
        }
      }
    },
    update_tran_speaker_label(state, { params, body }) {
      if (state.media) {
        if (!(state.media.hashid === params.hashid && state.media.belongs_to === params.belongs_to)) {
          return;
        }

        if (params.is_all) {
          // Update the speaker_label in all objects where it matches the old label
          state.media.transcriptions.forEach((transcript) => {
            if (transcript.speaker_label === body.old_speaker_label) {
              transcript.speaker_label = params.speaker_label;
            }
          });
        } else {
          const index = state.media.transcriptions.findIndex((t) => t.id === params.id);
          if (index > -1) {
            state.media.transcriptions[index].speaker_label = params.speaker_label;
          }
        }
      }
    },
    setVisibility(state, value) {
      state.visibility = value;
    },

    // command
    addUploadedFile(state, file) {
      state.uploadedFiles.push(file);
    },
    updateUploadedFile(state, data) {
      const index = state.uploadedFiles.findIndex((f) => f.id === data.id);
      if (index > -1) {
        state.uploadedFiles.splice(index, 1, { ...state.uploadedFiles[index], ...data });
      }
    },
    destroyUploadedFile(state, file) {
      const index = state.uploadedFiles.findIndex((f) => f.signed_id === file.signed_id);
      if (index > -1) {
        state.uploadedFiles.splice(index, 1);
      }
    },

    modalData(state, data) {
      state.uploadedFiles = [];
      state.modal = (typeof data === "string" ? { action: data } : data) || null;
    },
    DestroyLog(state, log) {
      this.$cable.perform({
        channel: MEDIAME_MODULE_NAME,
        action: "destroy_log",
        data: {
          hashid: state.media.hashid,
          belongs_to: state.media.belongs_to,
          id: log.id,
          log: log,
        },
      });
    },
    addSelectedMedia(state, itemArray) {
      state.selectedMedia = itemArray;
    },
    resetSelectedMedia(state) {
      state.selectedMedia = [];
    },
    setLoading(state, { action: action_name, flag = true }) {
      state.isLoading[action_name] = flag;
    },
    setSearchQuery(state, query) {
      state.query = query;
    },
    setAppliedFilter(state, appliedFilter) {
      state.appliedFilter = appliedFilter;
    },
    setSort(state, value) {
      state.sort = value;
    },
    setType(state, value) {
      state.type = value;
    },
    setPaginationData(state, params) {
      for (const key in params) if (params[key]) state[key] = params[key];
    },
    changeTab(state, new_tab_key) {
      state.activeTab = new_tab_key;
    },
    setLocalStorageFavKey(state, key) {
      state.localStorageFavKey = key;
    },
    setAsOldUser(state, flag = true) {
      state.is_new_user = !flag;
    },
    getFavorites(state) {
      state.favorites = JSON.parse(localStorage.getItem(state.localStorageFavKey));
    },
    toggleFavorite(state, media) {
      let fav_temp = [];
      if (!state.favorites) fav_temp = [media];
      else {
        const index = state.favorites.findIndex((item) => item.hashid === media.hashid);
        fav_temp = [...state.favorites];

        if (index !== -1) fav_temp.splice(index, 1);
        else fav_temp.push(media);
      }

      state.favorites = [...fav_temp];

      localStorage.setItem(state.localStorageFavKey, JSON.stringify(state.favorites));
    },
    addGetStartedActionPerformed(state, action) {
      state.get_started_actions_performed = [...state.get_started_actions_performed, action];
    },
    setGetStartedMetadata(state, { key, value }) {
      state.get_started_metadata[key] = value;
    },
    setMediaTranscriptionStatus(state, { hashid, belongs_to, status }) {
      const index = state.media_list.findIndex((m) => m.hashid === hashid && m.belongs_to === belongs_to);
      if (index > -1) state.media_list[index].transcription_analysis_status = status;
    },
  },
  actions: {
    mountPlayer({ commit, dispatch, state, getters }, params) {
      $("#dvr-playback-take-video").empty();

      const hideMarkers = params?.hide_markers;

      if (!state.media) {
        return;
      }

      let url = state.media.playback_url;
      for (let quality of ["/low.mp4", "/medium.mp4", "/high.mp4"]) {
        url = url.replace(quality, ".m3u8");
      }

      __takePlayer = new Innoplay.Player({
        parentId: "#dvr-playback-take-video",
        source: url,
        poster: state.media.thumbnail_url ? state.media.thumbnail_url.replace("?width=300", "") : undefined,
        width: "100%",
        height: "100%",
        autoPlay: false,
        mute: false,
        playback: {
          playInline: true,
          crossOrigin: false,
        },
        persistConfig: false,
        logBar: {
          placeholder: "Type here to log video",
        },
        hlsUseNextLevel: true,
        hlsPlayback: {
          playInline: true,
          crossOrigin: false,
          preload: true,
        },
        hideMediaControl: false,
        fps: state.media.frame_rate,
        markers: state.media.logs.map((log) =>
          Innoplay.Plugins.Markers.StandardMarker.create(log.id, Number(log.timecode_in), log.comment)
        ),
        onRemoveMarker: function (marker) {
          const log = state.media.logs.find((l) => l.id === marker.id);
          if (log) {
            commit("DestroyLog", log);
          }
        },
      });

      if (hideMarkers) __takePlayer.markers = [];

      // try {
      // if (!IS_DEV) {
      __takePlayer.getPlugin(Innoplay.Plugins.LevelSelector.name).disable();
      __takePlayer.getPlugin(Innoplay.Plugins.LogBar.name).disable();
      if (state.media?.name?.endsWith(".mp3") || state.media?.name?.endsWith(".wav")) {
        console.log("Disabling Aspect Ratio Plugin for Audio");
        __takePlayer.getPlugin(Innoplay.Plugins.Rotate.name).disable();
        __takePlayer.getPlugin(Innoplay.Plugins.AspectRatio.name).disable();
        __takePlayer.getPlugin(Innoplay.Plugins.PlaybackRate.name).disable();
        __takePlayer.getPlugin(Innoplay.Plugins.CameraSwitcher.name).disable();
      }
      // }
      // } catch (e) {
      //   console.log(e);
      // }

      // player events
      __takePlayer.on("timeupdate", function (event) {
        $(document).trigger("videoPlayerTimeUpdate", event.current);
      });
    },
    /**
     * Destroys the video player instance.
     *
     * @param {Object} commit - The commit object used for Vuex state management.
     * @return {void}
     */
    destroyPlayer({ commit }) {
      if (__takePlayer) {
        __takePlayer.destroy();
        __takePlayer = null;
        commit("show", { body: null });
      }
    },
    seekPlayer({}, time) {
      if (__takePlayer) {
        __takePlayer.seek(time);
      }
    },
    /**
     * Adds a log entry in the player.
     *
     * @param {object} logData - The log data object.
     * @param {object} logData.id - The log ID.
     * @param {number} logData.timecode_in - The timecode in seconds.
     * @param {string} logData.comment - The log comment.
     * @param log
     */
    addLogInPlayer({}, log) {
      if (__takePlayer) {
        __takePlayer
          .getPlugin(Innoplay.Plugins.Markers.name)
          .addMarker(Innoplay.Plugins.Markers.StandardMarker.create(log.id, Number(log.timecode_in), log.comment));
      }
    },
    /**
     * Removes the specified log from the player's marker.
     *
     * @param {Object} __takePlayer - The player object.
     * @param {Object} log - The log object to be removed.
     */
    removeLogInPlayer({}, log) {
      if (__takePlayer) {
        __takePlayer.getPlugin(Innoplay.Plugins.Markers.name).removeMarkerById(log.id);
      }
    },
    // close - this.manageModal();
    // add - this.manageModal("add")
    // edit - this.manageModal("edit")
    // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
    manageModal({ commit }, data) {
      commit("modalData", data);
      $("#mediame-modal").modal(data ? "show" : "hide");
    },
    setVisibility({ commit }, data) {
      commit("setVisibility", data);
    },
    updateTranscriptionSegment({ state }, data) {
      const index = state.media.transcriptions.findIndex((el) => el.id === data.id);
      if (index !== -1) {
        state.media.transcriptions.splice(index, 1, data);
      }
    },
    toggleMediaStar({ state }, hashid) {
      const media_item = state.media_list.find((m) => m.hashid === hashid);
      media_item.starred = !media_item.starred;
    },
  },
  getters: {
    visibility: (s) => s.visibility,
    is_connected: (s) => s.is_connected,
    is_new_user: (s) => s.is_new_user,

    modal: (s) => s.modal,
    get_started_actions_performed: (s) => s.get_started_actions_performed,
    get_started_metadata: (s) => s.get_started_metadata,

    media_list: (s) => s.media_list,
    media: (s) => s.media,
    favorites: (s) => s.favorites,
    tags_list: (s) => s.tags_list,
    tags_list_with_id: (s) => s.tags_list_with_id,
    isLoading: (s) => s.isLoading,

    query: (s) => s.query,
    appliedFilter: (s) => s.appliedFilter,
    sort: (s) => s.sort,
    type: (s) => s.type,

    startIndex: (s) => s.startIndex,
    perPage: (s) => s.perPage,
    endIndex: (s) => s.endIndex,
    currentPage: (s) => s.currentPage,
    activeTab: (s) => s.activeTab,

    // for upload modal only
    uploadedFiles: (s) => s.uploadedFiles,
    localStorageFavKey: (s) => s.localStorageFavKey,

    deliver_me_media_list: (s) => s.media_list.filter((v) => v.belongs_to === "deliver_me"),
    direct_me_media_list: (s) => s.media_list.filter((v) => v.belongs_to === "direct_me"),
    media_me_media_list: (s) => s.media_list.filter((v) => v.belongs_to === "media_me"),

    selected_media_list: (s) => s.selectedMedia,

    media_list_by_tag: (s) => (tag_id) => {
      return s.media_list.filter((each_media) => {
        console.group("media List By Tag");
        console.log("media List By Tag :", tag_id);
        console.log("each_media", each_media);
        console.groupEnd();
        const tag_idx = each_media.tag_obj.findIndex(
          (each_media_item_tag) => parseInt(each_media_item_tag.id) === parseInt(tag_id)
        );
        return tag_idx > -1;
      });
    },
    media_list_to_use:
      (s, g) =>
      ({ id, name }) => {
        if (name === "mediames.smart-tags.index") return g.media_list_by_tag(id);
        else if (g.activeTab === "favorites") return g.favorites;

        return g.media_list;
      },
    items:
      (s, g) =>
      ({ id, name }) => {
        const searchTerm = g.query.toLowerCase().trim();
        let items = g.media_list_to_use({ id, name })?.slice();
        if (!items) return null;
        if (searchTerm)
          items = items.filter((media) => {
            const nameMatch = media.name?.toLowerCase().includes(searchTerm);
            const tagMatch = media.tag_list?.some((tag) => tag.toLowerCase().includes(searchTerm));
            const descMatch = media.description?.toLowerCase().includes(searchTerm);
            return nameMatch || tagMatch || descMatch;
          });

        if (g.appliedFilter && g.appliedFilter !== "All") {
          items = items.filter((media) => {
            const mediaTags = media.tag_list || [];
            if (["DirectME", "DeliverME", "MediaME"].includes(g.appliedFilter)) {
              return media.belongs_to.toLowerCase() === SOURCE[g.appliedFilter];
            }
            return (
              mediaTags.some((tag) => tag.toLowerCase() === g.appliedFilter.toLowerCase()) ||
              media.type?.toLowerCase() === g.appliedFilter.toLowerCase()
            );
          });
        }
        // Sorting logic based on the sort value
        let type = g.sort;
        items.sort(MEDIAME_SORT_OPTIONS[type].algo);

        const startIndex = (g.currentPage - 1) * g.perPage;
        const endIndex = startIndex + g.perPage - 1;
        items = items.slice(startIndex, endIndex + 1);
        return items;
      },
  },
};

export const mapMediaMEActions = (data) => mapActions(MEDIAME_MODULE_NAME, data);
export const mapMediaMEGetters = (data) => mapGetters(MEDIAME_MODULE_NAME, data);

export const mapMediaMEMutations = (data) => mapMutations(MEDIAME_MODULE_NAME, data);

export default MediaMEModule;
